<template>
    <div class="app-container">
        <div class="code-box">
            <el-row :gutter="40">
                <el-col :xs="24" :sm="12">
                    <div>
                        <el-image :src="srcList[0]" fit="contain" :preview-src-list="srcList"></el-image>
                        <div class="name">秀动<span class="color-theme">微信小程序</span>票夹二维码</div>
                        <el-button type="primary" @click="$utils.exportFileUrl(srcList[0], '秀动微信小程序票夹二维码.jpg')">下载二维码</el-button>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="12">
                    <div>
                        <el-image :src="srcList[1]" fit="contain" :preview-src-list="srcList"></el-image>
                        <div class="name">秀动<span class="color-theme">H5</span>票夹二维码</div>
                        <el-button type="primary" @click="$utils.exportFileUrl(srcList[1], '秀动H5票夹二维码.png')">下载二维码</el-button>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
const wxcode = require('@/assets/img/ticketCode/wx-mini.jpg')
const h5code = require('@/assets/img/ticketCode/h5.png')
export default {
    data() {
        return {
            srcList: [wxcode, h5code]
        }
    }
}
</script>
<style lang="scss" scoped>
.app-container {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 100%;

    .code-box {

        .el-col {
            text-align: center;

            >div {
                background: #fff;
                padding: 20px;
            }
        }

        .el-image {
            width: 300px;
            height: 300px;
        }
        .name{
            margin: 15px 0;
            font-size: 14px;
        }
    }
}
</style>